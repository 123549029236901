import { Subscriptions } from '@/assets/icons'
import { InviteFriends } from '@/components/CommonPopups/InviteFriendsPopup'
import { Reclaim } from '@/components/CommonPopups/Reclaim'
import { SubscriptionsPage } from '@/pages/overview/subscriptions'
import { SubscriptionContainerPage } from '@/pages/overview/subscriptions/Subscription'
import { Chat } from '@/pages/overview/subscriptions/Subscription/common/Chat/Chat'
import { SubscriptionTransactions } from '@/pages/overview/subscriptions/Subscription/common/SubscriptionTransactions'
import { ServiceDetails } from '@/pages/overview/subscriptions/Subscription/join/ServiceDetails'
import { ServiceFeedback } from '@/pages/overview/subscriptions/Subscription/join/popups/ServiceFeedbackPopUp'
import { Details } from '@/pages/overview/subscriptions/Subscription/offer/Details'
import { RemoveService } from '@/pages/overview/subscriptions/Subscription/offer/Details/popups/RemoveService'
import { RemoveServiceError } from '@/pages/overview/subscriptions/Subscription/offer/Details/popups/RemoveServiceError'
import { OfferOverview } from '@/pages/overview/subscriptions/Subscription/offer/Overview'
import { SlotReactivate } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotReactivate'
import { SlotReserve } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotReserve'
import { SlotUserAdd } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotUserAdd'
import { SlotUserBlock } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotUserBlock'
import { SlotUserBlockSuccess } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotUserBlockSuccess'
import { SlotUserRefund } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotUserRefund'
import { SlotUserRefundSuccess } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotUserRefundSuccess'
import { SlotUserRemove } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotUserRemove'
import { SlotUserUnblock } from '@/pages/overview/subscriptions/Subscription/offer/Overview/popups/SlotUserUnblock'
import { Requests } from '@/pages/overview/subscriptions/Subscription/offer/Requests'
import { AcceptRequest } from '@/pages/overview/subscriptions/Subscription/offer/Requests/popups/AcceptRequest'
import { AcceptRequestSuccess } from '@/pages/overview/subscriptions/Subscription/offer/Requests/popups/AcceptRequestSuccess'
import { CreateAccount } from '@/pages/overview/subscriptions/Subscription/offer/Requests/popups/CreateAccount'
import { DeclineRequest } from '@/pages/overview/subscriptions/Subscription/offer/Requests/popups/DeclineRequest'
import { WarningRequest } from '@/pages/overview/subscriptions/Subscription/offer/Requests/popups/WarningRequest'
import { JoinSubscriptionPopup } from '@/pages/overview/subscriptions/popups/JoinSubscription'
import { JoinSubscriptionErrorPopup } from '@/pages/overview/subscriptions/popups/JoinSubscriptionError'
import { JoinSubscriptionPaymentPopup } from '@/pages/overview/subscriptions/popups/JoinSubscriptionPayment'
import { JoinSubscriptionServicePopup } from '@/pages/overview/subscriptions/popups/JoinSubscriptionServicePicker'
import { JoinSubscriptionSuccessPopup } from '@/pages/overview/subscriptions/popups/JoinSubscriptionSuccess'
import { OfferSubscriptionPopup } from '@/pages/overview/subscriptions/popups/OfferSubscription'
import { OfferSubscriptionDetailsPopup } from '@/pages/overview/subscriptions/popups/OfferSubscriptionDetails'
import ProviderRequest from '@/pages/overview/subscriptions/popups/ProviderRequest.tsx'
import { ServiceRemoved } from '@/pages/overview/subscriptions/popups/ServiceRemoved'
import { SubscriptionSelectPopup } from '@/pages/overview/subscriptions/popups/SubscriptionSelect'
import type { RouteObject } from 'react-router-dom'

export const subscriptionRoutes: RouteObject[] = [
  {
    path: 'subscriptions',
    element: <SubscriptionsPage />,
    handle: {
      label: 'navbar.links.subscriptions',
      icon: Subscriptions
    },
    children: [
      {
        path: 'new-join',
        children: [
          {
            index: true,
            element: <SubscriptionSelectPopup type="join" />
          },
          {
            path: ':id',
            element: <JoinSubscriptionPopup />
          },
          {
            path: ':id/service',
            element: <JoinSubscriptionServicePopup />
          },
          {
            path: ':id/service/:serviceId/payment',
            element: <JoinSubscriptionPaymentPopup />
          },
          {
            path: ':id/service/:serviceId/success',
            element: <JoinSubscriptionSuccessPopup />
          },
          {
            path: ':id/service/:serviceId/error',
            element: <JoinSubscriptionErrorPopup />
          }
        ]
      },
      {
        path: 'new-offer',
        children: [
          {
            index: true,
            element: <SubscriptionSelectPopup type="offer" />
          },
          {
            path: ':id',
            element: <OfferSubscriptionPopup />
          },
          {
            path: ':id/account-details',
            element: <OfferSubscriptionDetailsPopup />
          }
        ]
      },
      {
        path: 'popup/service-removed',
        element: <ServiceRemoved />
      },
      {
        path: 'popup/provider-request',
        element: <ProviderRequest />
      }
    ]
  },
  {
    path: 'subscriptions/offer/:id',
    element: <SubscriptionContainerPage type="offer" />,
    handle: {
      label: 'navbar.links.subscriptions',
      icon: Subscriptions
    },
    children: [
      {
        path: 'overview',
        element: <OfferOverview />,
        children: [
          {
            path: 'popup/invite-friends',
            element: <InviteFriends />
          },
          {
            path: 'popup/reclaim-money',
            element: <Reclaim />
          },
          {
            path: 'user/add',
            element: <SlotUserAdd />
          },
          {
            path: 'user/reserve',
            element: <SlotReserve />
          },
          {
            path: 'user/reactivate',
            element: <SlotReactivate />
          },
          {
            path: 'user/remove/:slotId',
            element: <SlotUserRemove />
          },
          {
            path: 'user/block/:slotId',
            element: <SlotUserBlock />
          },
          {
            path: 'user/block/:slotId/success',
            element: <SlotUserBlockSuccess />
          },
          {
            path: 'user/unblock/:slotId',
            element: <SlotUserUnblock />
          },
          {
            path: 'user/refund/:slotId',
            element: <SlotUserRefund />
          },
          {
            path: 'user/refund/:slotId/success',
            element: <SlotUserRefundSuccess />
          }
        ]
      },
      {
        path: 'chat',
        element: <Chat />
      },
      {
        path: 'requests',
        element: <Requests />,
        children: [
          {
            path: 'popup/invite-friends',
            element: <InviteFriends />
          },
          {
            path: 'popup/accept-request/:requestId',
            element: <AcceptRequest />
          },
          {
            path: 'popup/accept-request/:requestId/create-account',
            element: <CreateAccount />
          },
          {
            path: 'popup/accept-request/:requestId/success',
            element: <AcceptRequestSuccess />
          },
          {
            path: 'popup/decline-request/:requestId',
            element: <DeclineRequest />
          },
          {
            path: 'popup/warning',
            element: <WarningRequest />
          }
        ]
      },
      {
        path: 'transactions',
        element: <SubscriptionTransactions type="offer" />,
        children: [
          {
            path: 'popup/invite-friends',
            element: <InviteFriends />
          }
        ]
      },
      {
        path: 'details',
        element: <Details />,
        children: [
          {
            path: 'popup/remove-service',
            children: [
              {
                index: true,
                element: <RemoveService />
              },
              {
                path: 'error',
                element: <RemoveServiceError />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'subscriptions/join/:id',
    element: <SubscriptionContainerPage type="join" />,
    handle: {
      label: 'navbar.links.subscriptions',
      icon: Subscriptions
    },
    children: [
      {
        path: 'service-details',
        element: <ServiceDetails />,
        children: [
          {
            path: 'popup/invite-friends',
            element: <InviteFriends />
          },
          {
            path: 'popup/give-feedback/:serviceId',
            element: <ServiceFeedback />
          }
        ]
      },
      {
        path: 'chat',
        element: <Chat />
      },
      {
        path: 'transactions',
        element: <SubscriptionTransactions type="join" />,
        children: [
          {
            path: 'popup/invite-friends',
            element: <InviteFriends />
          }
        ]
      }
    ]
  }
]

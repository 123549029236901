import {
  ArrowSecondary,
  Forbidden,
  HourGlass,
  HourGlassHorizontal
} from '@/assets/icons'
import { Idea, JoinServiceInviteFriends, Tea } from '@/assets/illustrations'
import { Avatar1 } from '@/assets/images'
import { ImgProfile } from '@/components/ImgProfile'
import { Button, TextInput } from '@/components/Inputs'
import { LinkButton } from '@/components/Inputs/Button'
import { PopupContainer, PopupOutlet } from '@/components/Popup'
import { Spinner } from '@/components/Spinner'
import { gql } from '@/gql'
import { RequestStatus, SlotState } from '@/gql/graphql.ts'
import { AuthContext } from '@/providers/Auth/context'
import { writeToClipboard } from '@/utils/clipboard.ts'
import { toastError } from '@/utils/toast'
import { useMutation, useQuery } from '@apollo/client'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { useFormik } from 'formik'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import invariant from 'tiny-invariant'
import * as yup from 'yup'
import { LeaveSubscription } from './popups/LeaveSubscription'
import { SuccessMessage } from './popups/SuccessMessage'
import { WithdrawSubscription } from './popups/WithdrawSubscription'

const SERVICE_DETAILS = gql(`
  query getServiceDetails($serviceId: String!) {
    service: service(where: { id: $serviceId }) {
      owner {
        firstName
        fullName
        avatar
      }
      provider {
        name
        website
        price {
          fullPrice
        }
        joinerEmailRequired
      }
      requests {
        status
        requestById
        id
        joinerEmail
      }
      slots {
        status
        usedById
        paymentDueDate
      }
      credentials {
        email
        password
      }
    }
  }
`)

const UPDATE_REQUEST = gql(`
  mutation UpdateRequest(
    $joinerEmail: String!
    $requestId: String
  ){
    updateRequest(
      data: {
        joinerEmail:$joinerEmail
      }
      where: {
        id:$requestId
      }
    ) {
    joinerEmail
  }
}
`)

export const ServiceDetails: React.FC = () => {
  const { t } = useTranslate()
  const [updateRequest] = useMutation(UPDATE_REQUEST)

  const validationSchema = yup.object().shape({
    joinerEmail: yup
      .string()
      .email(t('form.inputs.errors.email'))
      .required(t('form.inputs.errors.email'))
  })

  const formik = useFormik({
    initialValues: {
      requestId: '',
      joinerEmail: ''
    },
    validationSchema,
    onSubmit: (values) => {
      invariant(values.joinerEmail, 'email is required')
      try {
        updateRequest({
          variables: {
            joinerEmail: values.joinerEmail,
            requestId: values.requestId
          }
        }).then(() => {
          setShowSuccessPopUp(true)
          refetch()
        })
      } catch (error) {
        toastError(t('toastMessages.submitJoinerEmail.error'))
      }
    }
  })

  const { id: serviceId } = useParams<{ id: string }>()
  invariant(serviceId, 'Service ID should be provided by route')

  const { currentUser } = useContext(AuthContext)!

  const [showCancelPopup, setShowCancelPopup] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false)

  const { data, loading, refetch } = useQuery(SERVICE_DETAILS, {
    variables: { serviceId }
  })

  const { state } = useLocation()
  useEffect(() => {
    if (state === 'refetch') {
      refetch()
      setShowCancelPopup(false)
    }
  }, [refetch, state])

  if (loading) return <Spinner />
  invariant(data, 'Data should be loaded')
  const { service } = data

  const request = (
    service.requests as {
      status: string
      requestById: string
      id: string
      joinerEmail: string
    }[]
  ).find((request) => request.requestById === currentUser?.id)!

  formik.initialValues.joinerEmail = request.joinerEmail ?? ''
  formik.initialValues.requestId = request.id

  const slot = (
    service.slots as {
      status: string
      usedById: string
      paymentDueDate: string
    }[]
  ).find((slot) => slot?.usedById === currentUser?.id)

  let credentials: { email: string; password: string } = service.credentials
  if (request.status === RequestStatus.Pending) {
    credentials = {
      email: '********',
      password: '********'
    }
  }

  return (
    <div className="flex max-md:flex-col gap-6">
      <div className="min-w-[340px]">
        <div
          className="bg-blue-dodger/20 flex flex-col gap-6 px-20 py-10
            max-md:flex-row max-md:items-center max-md:px-4 max-md:justify-between"
        >
          <div className="flex flex-col items-center text-center max-md:basis-full">
            <ImgProfile img={service.owner.avatar} className="mb-4 w-16 h-16" />
            <h5 className="text-xs">{service.owner.fullName}</h5>
            <h5 className="text-gray-shuttle-soft text-xs">
              <T
                keyName="components.joinSubscription.joinDetails.serviceDetails.accountOwner"
                params={{
                  service: () => service.provider.name
                }}
              />
            </h5>
          </div>
          <div className="flex justify-center max-md:basis-full">
            {request.status === RequestStatus.Pending ? (
              <>
                <HourGlass className="max-md:hidden" />
                <HourGlassHorizontal className="md:hidden" />
              </>
            ) : (
              <ArrowSecondary className="md:rotate-90" />
            )}
          </div>
          <div className="flex flex-col items-center text-center max-md:basis-full">
            <div className="mb-4 relative">
              <ImgProfile
                img={currentUser?.avatar ?? Avatar1}
                className={twJoin(
                  'w-16 h-16 border-[3px] border-transparent shadow-[0_0_0_3px]',
                  request.status === RequestStatus.Pending &&
                    'opacity-50 shadow-blue-dodger',
                  slot?.status === SlotState.ToRemove
                    ? 'shadow-pink-brink/50'
                    : 'shadow-blue-dodger/50'
                )}
              />
              {slot?.status === SlotState.ToRemove && (
                <Forbidden
                  className="w-8 h-8 absolute bottom-[10%] right-[40%] [&_path]:stroke-white
                [&_circle]:fill-pink-brink [&_circle]:stroke-pink-brink"
                />
              )}
            </div>
            <h5 className="text-xs">
              <T keyName="components.joinSubscription.joinDetails.serviceDetails.you" />
            </h5>
            <h5 className="text-gray-shuttle-soft text-xs">
              {service.provider.joinerEmailRequired && request.joinerEmail}
              {!service.provider.joinerEmailRequired && (
                <T
                  keyName={`components.joinSubscription.joinDetails.serviceDetails.${
                    request.status === 'PENDING'
                      ? 'waiting'
                      : slot?.status === SlotState.ToRemove
                        ? 'daysLeft'
                        : 'active'
                  }`}
                  params={{
                    owner: () => service.owner.firstName,
                    days: () =>
                      moment(slot?.paymentDueDate).diff(moment(), 'days')
                  }}
                />
              )}
            </h5>
          </div>
        </div>
        {slot?.status !== SlotState.ToRemove && (
          <h6 className="text-xs mt-4 text-center">
            <T
              keyName={`components.joinSubscription.joinDetails.serviceDetails.${
                request.status === 'PENDING' ? 'cancelAnyTime' : 'notHappy'
              }`}
              params={{
                cancel: () => (
                  <a
                    onClick={() => setShowCancelPopup(true)}
                    className="text-blue-dodger"
                  >
                    <T keyName="components.joinSubscription.joinDetails.serviceDetails.cancel" />
                  </a>
                )
              }}
            />
          </h6>
        )}
      </div>
      <div className="flex-grow flex flex-col gap-6">
        {!service.provider.joinerEmailRequired && (
          <>
            <div className="flex justify-between items-end">
              <h3>
                <T
                  keyName="components.joinSubscription.joinDetails.serviceDetails.credentialsTitle"
                  params={{
                    service: () => service.provider.name
                  }}
                />
              </h3>
              <a
                className="block text-blue-dodger text-xs"
                href={service.provider.website ?? '#'}
                target="_blank"
                rel="noreferrer"
              >
                <T
                  keyName="components.joinSubscription.joinDetails.serviceDetails.goToService"
                  params={{
                    service: () => service.provider.name
                  }}
                />
              </a>
            </div>

            <TextField
              label={
                <T
                  keyName="form.inputs.labels.serviceAccountIdentifier"
                  params={{
                    provider: () => service.provider.name
                  }}
                />
              }
              value={credentials.email}
              InputProps={
                request.status === RequestStatus.Accepted
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <h6
                            className="text-xs text-blue-dodger"
                            onClick={() => writeToClipboard(credentials.email)}
                          >
                            Copy
                          </h6>
                        </InputAdornment>
                      )
                    }
                  : undefined
              }
              disabled={request.status !== RequestStatus.Accepted}
              inputProps={{ readOnly: true }}
            />

            <TextField
              label={
                <T
                  keyName="form.inputs.labels.serviceAccountPassword"
                  params={{
                    provider: () => service.provider.name
                  }}
                />
              }
              type={showPassword ? 'text' : 'password'}
              value={credentials.password}
              InputProps={
                request.status === RequestStatus.Accepted
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((prev) => !prev)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          <h6
                            className="ml-2 text-xs text-blue-dodger"
                            onClick={() =>
                              writeToClipboard(credentials.password)
                            }
                          >
                            Copy
                          </h6>
                        </InputAdornment>
                      )
                    }
                  : undefined
              }
              disabled={request.status !== RequestStatus.Accepted}
              inputProps={{ readOnly: true }}
            />
          </>
        )}

        {service.provider.joinerEmailRequired && (
          <>
            <div className="flex justify-between items-end">
              <h3>
                <T
                  keyName="components.joinSubscription.joinDetails.serviceDetails.joinerEmailTitle"
                  params={{
                    service: () => service.provider.name
                  }}
                />
              </h3>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col mt-2 mb-1 max-md:mb-2">
                <TextInput
                  name="joinerEmail"
                  formik={formik}
                  label={t('form.inputs.labels.joinerEmail', {
                    provider: () => data.service.provider.name.split(' ')[0]
                  })}
                />
              </div>
              <div className="mt-2 flex justify-end items-center">
                <Button
                  type="submit"
                  disabled={
                    !formik.isValid ||
                    formik.values.joinerEmail === request.joinerEmail
                  }
                >
                  <T keyName="components.serviceDetails.joinerEmail.submit.btn" />
                </Button>

                <LinkButton
                  className="ml-2"
                  to={data.service.provider.website ?? '#'}
                  target="_blank"
                >
                  <T
                    keyName="components.offerSubscription.offerDetails.details.goToService"
                    params={{
                      service: () => data.service.provider.name
                    }}
                  />
                </LinkButton>
              </div>
            </form>
          </>
        )}
        {request.status === RequestStatus.Accepted && (
          <div className="border border-dashed border-bombay flex items-center py-1 px-4">
            <p className={twJoin('flex-grow', 'text-xs text-gray-shuttle')}>
              <T
                keyName={
                  'components.joinSubscription.joinDetails.serviceDetails.description-give-feedback'
                }
                params={{
                  feedback: () => (
                    <Link
                      to={`popup/give-feedback/${serviceId}`}
                      className="text-blue-dodger block mt-2"
                    >
                      <T keyName="components.joinSubscription.joinDetails.serviceDetails.link.give-feedback" />
                    </Link>
                  )
                }}
              />
            </p>
            <img src={Idea} alt="" />
          </div>
        )}

        <div className="border border-dashed border-bombay flex items-center py-1 px-4">
          <p
            className={twJoin(
              'flex-grow',
              request.status === RequestStatus.Pending
                ? 'text-sm text-black'
                : 'text-xs text-gray-shuttle'
            )}
          >
            <T
              keyName={
                request.status === RequestStatus.Pending
                  ? 'components.joinSubscription.joinDetails.serviceDetails.waitingInviteFriends.info'
                  : 'components.joinSubscription.joinDetails.serviceDetails.acceptedInviteFriends.info'
              }
              params={{
                owner: () => service.owner.firstName,
                invite: () => (
                  <Link
                    to="popup/invite-friends"
                    className="text-blue-dodger block mt-2"
                  >
                    <T keyName="components.joinSubscription.joinDetails.serviceDetails.acceptedInviteFriends.invite" />
                  </Link>
                )
              }}
            />
          </p>
          <img
            src={
              request.status === RequestStatus.Pending
                ? Tea
                : JoinServiceInviteFriends
            }
            alt=""
          />
        </div>

        <div className="flex justify-between items-end">
          <h3>
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.paymentsTitle" />
          </h3>
          <Link to="../transactions" className="text-blue-dodger text-xs">
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.viewAll" />
          </Link>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center pb-2 border-b border-b-porcelain">
            <h4 className="text-sm text-gray-shuttle">
              <T keyName="components.joinSubscription.joinDetails.serviceDetails.subscriptionValue" />
            </h4>
            <h4 className="text-sm">
              <T
                keyName="components.joinSubscription.joinDetails.serviceDetails.monthlyPrice"
                params={{
                  symbol: () => <T keyName="currency.symbol.euro" />,
                  price: () => service.provider.price.fullPrice.toFixed(2),
                  month: () => (
                    <T keyName="components.joinSubscription.joinDetails.serviceDetails.month" />
                  )
                }}
              />
            </h4>
          </div>

          <div className="flex justify-between items-center pb-2 border-b border-b-porcelain">
            <h4 className="text-sm text-gray-shuttle">
              <T keyName="components.joinSubscription.joinDetails.serviceDetails.previousPayment" />
            </h4>
            <h4 className="text-sm">
              {request.status === RequestStatus.Pending
                ? '-'
                : moment(slot?.paymentDueDate)
                    .subtract(30, 'd')
                    .format('DD MMM YYYY')}
            </h4>
          </div>

          <div className="flex justify-between items-center pb-2 border-b border-b-porcelain">
            <h4 className="text-sm text-gray-shuttle">
              <T keyName="components.joinSubscription.joinDetails.serviceDetails.nextPayment" />
            </h4>
            <h4 className="text-sm">
              {request.status === RequestStatus.Pending ? (
                <T
                  keyName="components.joinSubscription.joinDetails.serviceDetails.wontBeCharged"
                  params={{
                    owner: () => service.owner.firstName
                  }}
                />
              ) : slot?.status === SlotState.ToRemove ? (
                '-'
              ) : (
                moment(slot?.paymentDueDate).format('DD MMM YYYY')
              )}
            </h4>
          </div>
        </div>
      </div>
      {showCancelPopup && (
        <PopupContainer>
          {slot && request.status === RequestStatus.Accepted ? (
            <LeaveSubscription
              onClose={() => setShowCancelPopup(false)}
              nextPaymentDate={slot?.paymentDueDate}
              serviceName={service.provider.name}
            />
          ) : (
            <WithdrawSubscription onClose={() => setShowCancelPopup(false)} />
          )}
        </PopupContainer>
      )}

      {showSuccessPopUp && (
        <PopupContainer>
          <SuccessMessage onClose={() => setShowSuccessPopUp(false)} />
        </PopupContainer>
      )}
      <PopupOutlet />
    </div>
  )
}

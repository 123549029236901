import { BrokenHeart, Idea, Success } from '@/assets/illustrations'
import { Button } from '@/components/Inputs'
import { PopupSkeleton } from '@/components/Popup/PopupSkeleton'
import { gql } from '@/gql'
import { SubscriptionFeedbackStatus } from '@/gql/graphql'
import { useMutation } from '@apollo/client'
import { TextareaAutosize } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const CreateSubscriptionFeedback = gql(`
 mutation CreateSubscriptionFeedback($feedback: String, $serviceId: String!, $status:SubscriptionFeedbackStatus!) {
    createSubscriptionFeedback(feedback: $feedback, serviceId: $serviceId, status:$status) {
      id
    }
  }
`)

export const ServiceFeedback: React.FC = () => {
  const [needFeedback, setNeedFeedback] = useState<boolean | null>(null)
  const feedbackRef = useRef<HTMLTextAreaElement>(null)
  const { t } = useTranslate()
  const navigate = useNavigate()
  const [createSubscriptionFeedback] = useMutation(CreateSubscriptionFeedback)
  const { serviceId } = useParams<{ serviceId: string }>()
  if (!serviceId) {
    throw new Error('Service ID is required')
  }

  const submitFeedback = async (feedback: string, feedbackStatus: boolean) => {
    await createSubscriptionFeedback({
      variables: {
        feedback,
        serviceId,
        status: feedbackStatus
          ? SubscriptionFeedbackStatus.Valid
          : SubscriptionFeedbackStatus.Invalid
      }
    })
  }
  return (
    <PopupSkeleton>
      {needFeedback === null && (
        <div className="w-[480px] h-full max-md:w-full p-10 text-center">
          <div className="flex justify-center mb-8">
            <img src={Idea} alt="" />
          </div>
          <h1 className="text-2xl mb-4">
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.give-feedback.title" />
          </h1>
          <h2 className="text-sm text-gray-shuttle">
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.give-feedback.info" />
          </h2>

          <div className="mt-8 flex justify-center gap-4">
            <Button
              onClick={() => {
                setNeedFeedback(true)
              }}
              className="border-red-500 text-white font-bold bg-red-500 hover:bg-red-600 hover:text-white"
            >
              <T
                keyName={
                  'components.joinSubscription.joinDetails.serviceDetails.give-feedback.invalid'
                }
              />
            </Button>
            <Button
              onClick={async () => {
                const feedback = feedbackRef.current?.value || ''
                await submitFeedback(feedback, true)
                setNeedFeedback(false)
              }}
              className="border-green-500 text-white font-bold bg-green-500 hover:bg-green-600 hover:text-white"
            >
              <T
                keyName={
                  'components.joinSubscription.joinDetails.serviceDetails.give-feedback.valid'
                }
              />
            </Button>
          </div>
        </div>
      )}
      {needFeedback === true && (
        <div className="w-[480px] h-full max-md:w-full p-10 text-center">
          <div className="flex justify-center mb-8">
            <img src={BrokenHeart} alt="" />
          </div>
          <h1 className="text-2xl mb-4">
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.invalid-feedback.title" />
          </h1>
          <h2 className="text-sm text-gray-shuttle">
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.invalid-feedback.info" />
          </h2>

          <>
            <TextareaAutosize
              name="feedback"
              className="w-full border border-gray-shuttle rounded-md p-4 mt-4 resize-none"
              minRows={5}
              maxRows={5}
              placeholder={t('form.inputs.placeholders.invalid-feedback')}
              ref={feedbackRef}
            />
          </>

          <Button
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white mt-2"
            onClick={async () => {
              const feedback = feedbackRef.current?.value || ''
              await submitFeedback(feedback, false)
              setNeedFeedback(false)
            }}
          >
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.invalid-feedback.submit" />
          </Button>
        </div>
      )}

      {needFeedback === false && (
        <div className="w-[480px] h-full max-md:w-full p-10 text-center">
          <div className="flex justify-center mb-8">
            <img src={Success} alt="" />
          </div>
          <h1 className="text-2xl mb-4">
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.feedback-done.title" />
          </h1>
          <h2 className="text-sm text-gray-shuttle">
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.feedback-done.info" />
          </h2>

          <Button
            className="border-blue-dodger text-blue-dodger hover:bg-blue-dodger hover:text-white mt-4"
            onClick={() => navigate(-1)}
          >
            <T keyName="components.joinSubscription.joinDetails.serviceDetails.feedback-done.close" />
          </Button>
        </div>
      )}
    </PopupSkeleton>
  )
}
